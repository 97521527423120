$(window).on('load', function() {
  $('.lp-btn2').on('click', function(){
    $(window).scrollTop(0);
  });

  $('.lp-btn3').on('click', function() {
    $(window).scrollTop($('body').get(0).scrollHeight)
  });

  var footer = $('.form-section').innerHeight();
  window.onscroll = function () {
    var point = window.scrollY;
    var docHeight = $(document).height();
    var dispHeight = $(window).height();
    if(point > docHeight-dispHeight-footer) {
      $('.lp-sticky-cta').slideUp(500, function() {
      });
    } else {
      $('.lp-sticky-cta').slideDown(500, function() {
      });
    }
  };
});
