import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap'
import '../stylesheets/application.scss';
import '../src/projects'
import '../src/home'
import '../src/lp'
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
window.$ = jQuery;
