$(window).on('load', function() {
  let elem = $('<canvas>')[0];
  // WebP形式の画像がブラウザでサポートされているかどうかを確認するためには、ブラウザがcanvas要素をサポートしているかどうかを確認する必要
  if (!(elem.getContext && elem.getContext('2d'))) {
    $('img[src$=".webp"]').each(function() {
      let $this = $(this);
      let src = $this.attr('src');
      let img = new Image();
      img.crossOrigin = "anonymous"; // crossorigin属性を追加
      img.src = src;
      // 画像を読み込んだ後の処理
      img.onload = function() {
        // canvasに描画する
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);

        // PNG形式に変換する
        let png = canvas.toDataURL('image/png');

        // ファイル名を取得する
        let filename = src.split('/').pop().replace(/\.webp$/, '');
        // imgタグのsrc属性を変更する
        $this.attr('src', png);

        // imgタグのファイル名を変更する
        $this.attr('download', filename + '.png');
      };
    });

  }
  $(function(){
    let already_checked_occupations = [];
    $('.occupation-check:checked').each(function() {
      already_checked_occupations.push($(this).parent().text().trim());
    });
    $('#occupation-selected-list').text(already_checked_occupations.join(", "));
    $('.home-search-item-txt'+'#occupation-selected-list').text('職種を選択');
    let already_checked_tags = [];
    $('.tag-check:checked').each(function() {
      already_checked_tags.push($(this).parent().text().trim());
    });
    $('#tag-selected-list').text(already_checked_tags.join(", "));
  });
  //チェックボックスをクリックするとチェックした一覧が表示されるロジック
  $('.occupation-check').on('change', function() {
    let checked_occupations = [];
    $('.occupation-check:checked').each(function() {
      checked_occupations.push($(this).parent().text().trim());
    });
    $('#occupation-selected-list').text(checked_occupations.join(", "));
    if(checked_occupations.length === 0) {
      $('.home-search-item-txt'+'#occupation-selected-list').removeClass("selected");
      $('.home-search-item-txt'+'#occupation-selected-list').text('職種を選択');
    } else {
      $('.home-search-item-txt'+'#occupation-selected-list').addClass("selected");
    }
  });
  $('.tag-check').on('change', function() {
    let checked_tags = [];
    $('.tag-check:checked').each(function() {
      checked_tags.push($(this).parent().text());
    });
    $('#tag-selected-list').text(checked_tags);
  });

  // 条件をリセットするボタンをクリックしたときの処理
  $('#resetAreaSearch').on('click', function(e){
    e.preventDefault();
    $('#prefecture-selected-list').empty();
    $('input[name="pref-checkbox"]').prop('checked', false);
    $('.home-search-item-txt'+'#prefecture-selected-list').append('勤務地を選択');
    $('.home-search-item-txt'+'#prefecture-selected-list').removeClass("selected");
  });
  $('#resetSytemObjects').on('click', function(e){
    e.preventDefault();
    $("[id^=system-object-selected-list-]").empty();
    $('input[name="system-object-checkbox"]').prop('checked', false)
  });
  $('#reset-occupations').on('click', function(){
    $('.occupation-check').prop('checked', false);
    $('#occupation-selected-list').text('');
    $('.home-search-item-txt'+'#occupation-selected-list').removeClass("selected");
    $('.home-search-item-txt'+'#occupation-selected-list').text('職種を選択');
  });
  $('#reset-tags').on('click', function(){
    $('.tag-check').prop('checked', false);
    $('#tag-selected-list').text('');
  });
});